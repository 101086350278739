export const postRecalculate = async (session_uuid, vertedero_parameter, vehicle_data, selected_date) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/recalculate_route`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_uuid,
          vehicle_data,
          vertedero_parameter,
          selected_date
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed operation: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
      console.error("Network error: Backend server is unreachable");
      throw new Error("Network error: Backend server is unreachable");
    } else {
      console.error("Error:", error);
      throw error;
    }
  }
};