// src/api/sessions.js
export const fetchSessions = async (days = 7, limit = 50) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/sessions?days=${days}&limit=${limit}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
            console.error("Network error: Backend server is unreachable");
            throw new Error("Network error: Backend server is unreachable");
        } else {
            console.error("Error:", error);
            throw error;
        }
    }
};

export const fetchSessionDetails = async (sessionUuid) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/sessions/${sessionUuid}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
            console.error("Network error: Backend server is unreachable");
            throw new Error("Network error: Backend server is unreachable");
        } else {
            console.error("Error:", error);
            throw error;
        }
    }
};

export const fetchSessionModifications = async (sessionUuid) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/sessions/${sessionUuid}/modifications`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
            console.error("Network error: Backend server is unreachable");
            throw new Error("Network error: Backend server is unreachable");
        } else {
            console.error("Error:", error);
            throw error;
        }
    }
};

export const deleteSession = async (sessionUuid) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/sessions/${sessionUuid}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
            console.error("Network error: Backend server is unreachable");
            throw new Error("Network error: Backend server is unreachable");
        } else {
            console.error("Error:", error);
            throw error;
        }
    }
};

export const fetchSessionStats = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/sessions/stats`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error(`Failed operation: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
            console.error("Network error: Backend server is unreachable");
            throw new Error("Network error: Backend server is unreachable");
        } else {
            console.error("Error:", error);
            throw error;
        }
    }
};