
// StaticRouteItem.js
import React from 'react';
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';

const StaticRouteItem = ({ item, nextItem }) => {
    const renderPopover = (props) => (
        <Popover id={`popover-main-${item.station_id}`} {...props}>
            <Popover.Header as="h3" style={{ backgroundColor: '#1D3557', color: '#ffffff' }}>
                Estación {item.client_name}
            </Popover.Header>
            <Popover.Body className="p-3">
                <p><strong>Dirección: </strong>{item.contact_address_complete}</p>
                <p><strong>Operación: </strong>{item.operation_type}</p>
                <p className="mb-0"><strong>Coordenadas: </strong>{item.location}</p>
            </Popover.Body>
        </Popover>
    );

    const renderNextItemPopover = (props) => (
        nextItem ? (
            <Popover id={`popover-vertedero-${nextItem.station_id}`} {...props}>
                <Popover.Header as="h3" style={{ backgroundColor: '#1D3557', color: '#ffffff' }}>
                    Estación {nextItem.client_name}
                </Popover.Header>
                <Popover.Body className="p-3">
                    <p><strong>Dirección: </strong>{nextItem.contact_address_complete}</p>
                    <p><strong>Operación: </strong>{nextItem.operation_type}</p>
                    <p className="mb-0"><strong>Coordenadas: </strong>{nextItem.location}</p>
                </Popover.Body>
            </Popover>
        ) : null
    );

    if (item.operation_type === "Vertedero" && !nextItem) {
        return null;  // Skip standalone Vertedero items
    }

    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="right"
            overlay={renderPopover}
            delay={500}
        >
            <ListGroup.Item className="p-2">
                <div className="fw-bold mb-1">{item.client_name}</div>
                <div className="d-flex justify-content-between align-items-center">
                    <small>Llegada: {item.arrival_time}</small>
                    <span className="badge bg-secondary">
                        {item.operation_type}
                    </span>
                </div>

                {nextItem && nextItem.operation_type === "Vertedero" && (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="bottom"
                        overlay={renderNextItemPopover}
                        delay={500}
                    >
                        <div className="mt-2 p-2 bg-light rounded">
                            <div className="small fw-bold">{nextItem.client_name}</div>
                            <div className="d-flex justify-content-between align-items-center">
                                <small>Llegada: {nextItem.arrival_time}</small>
                                <span className="badge bg-secondary">
                                    {nextItem.operation_type}
                                </span>
                            </div>
                        </div>
                    </OverlayTrigger>
                )}
            </ListGroup.Item>
        </OverlayTrigger>
    );
};

export default StaticRouteItem;