// StaticRouteList.js
import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import StaticRouteItem from './StaticRouteItem';

const StaticRouteList = ({ routeData }) => {
    return (
        <div className="d-flex overflow-auto pb-3">
            {routeData.stations.map((station) => (
                <Card
                    key={station.vehicle_id}
                    style={{
                        minWidth: '250px',
                        marginRight: '10px',
                        height: 'fit-content'
                    }}
                >
                    <Card.Header>
                        <h5 className="mb-0">Vehículo {station.vehicle_id + 1}</h5>
                        <small>Contenedores necesarios: {station.containers}</small>
                    </Card.Header>
                    <Card.Body className="p-2">
                        <div className="mb-3">
                            <small className="d-block">Costo total: ${station.route_cost?.toFixed(1)}</small>
                            <small className="d-block">Por distancia: ${station.cost_by_distance?.toFixed(1)}</small>
                            <small className="d-block">Por duración: ${station.cost_by_duration?.toFixed(1)}</small>
                        </div>
                        <ListGroup variant="flush">
                            {station.itinerary.map((item, index) => (
                                <StaticRouteItem
                                    key={`${item.task_id}-${index}`}
                                    item={item}
                                    nextItem={station.itinerary[index + 1]?.operation_type === "Vertedero" ? station.itinerary[index + 1] : null}
                                />
                            ))}
                        </ListGroup>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
};

export default StaticRouteList;