// Home.jsx

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row, Spinner, Tooltip } from 'react-bootstrap';
import { PlayFill } from 'react-bootstrap-icons';

// Component Imports
import DraggableLists from '../components/DraggableLists.jsx';
import ErrorMessage from '../components/ErrorMessage.jsx';
import SelectDriversModal from '../components/SelectDriversModal.jsx';
import SubmitModal from '../components/SubmitModal.jsx';
import HomeButtonBar from '../components/HomeButtonBar.jsx';
import TotalCostHome from '../components/TotalCostHome.jsx';
import AlertModal from '../components/AlertMessage.jsx';

// API Imports
import { fetchRoute } from '../api/fetchRoute.js';
import { postRemodel } from '../api/postRemodel.js';
import { postRecalculate } from '../api/postRecalculate.js';
import { postEditPoints } from '../api/postEditPoints.js';
import { postFinalData } from '../api/postFinalData.js';
import { fetchDrivers } from '../api/fetchDrivers.js';

// Assets and Styles
import './styles/Home.css';
import img_logo from "../imgs/logo_azul.png";

/**
 * Home Component
 * Main interface for route management and optimization.
 * Manages route creation, modification, and submission workflow.
 */
const Home = () => {
  // Router State
  const location = useLocation();
  const navigate = useNavigate();

  // Core Route Data State
  const [data, setData] = useState(null);
  const [firstIteration, setFirstIteration] = useState(null);
  const [sessionUuid, setSessionUuid] = useState(null);
  const [history, setHistory] = useState([]);

  // Driver and Configuration State
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [availableContainers, setAvailableContainers] = useState(1);
  const [vertederoParameter, setVertederoParameter] = useState('cheapest');

  // Process State Flags
  const [isFetching, setIsFetching] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [isRemodeling, setIsRemodeling] = useState(false);
  const [standbyListUsed, setStandByListUsed] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // UI State Management
  const [showNetworkError, setShowNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSelectDrivers, setShowSelectDrivers] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [historyMessage, setHistoryMessage] = useState("");
  const [standByList, setStandByList] = useState([]);

  /**
   * Restore state when returning from History page or handling restored versions
   */
  useEffect(() => {
    const sessionState = location.state;
    if (sessionState) {
      setData(sessionState.data);
      setFirstIteration(sessionState.firstIteration);
      setSessionUuid(sessionState.sessionUuid);
      setSelectedDrivers(sessionState.selectedDrivers);
      setSelectedDate(sessionState.selectedDate);
      setAvailableContainers(sessionState.availableContainers);
      setVertederoParameter(sessionState.vertederoParameter);
    }
  }, [location.state]);

  // ----- Event Handlers -----

  /**
   * Initialize driver selection process
   */
  const handleOpenDrivers = () => {
    setShowSelectDrivers(true);
    fetchDrivers()
      .then(setDrivers)
      .catch(error => {
        console.log("Error occurred while fetching drivers:", error.error);
        handleError(error);
      });
  };

  /**
   * Fetch initial route data based on selected parameters
   */
  const handleFetchData = () => {
    setIsFetching(true);
    fetchRoute(vertederoParameter, selectedDrivers, selectedDate, availableContainers)
      .then((fetchedData) => {
        setData(fetchedData.result);
        setSessionUuid(fetchedData.session_uuid);
        if (firstIteration === null) {
          setFirstIteration(fetchedData.result);
        }
        if (fetchedData.errors?.length > 0) {
          setShowAlertModal(true);
          setAlertMessage(fetchedData.errors.join("\n"));
          setHistoryMessage(fetchedData.history.join("\n"));
        }
      })
      .catch(handleError)
      .finally(() => setIsFetching(false));
  };

  /**
   * Reset route to initial state
   */
  const handleReset = () => {
    setIsResetting(true);
    fetchRoute(vertederoParameter, selectedDrivers, selectedDate, availableContainers)
      .then((fetchedData) => {
        setSessionUuid(fetchedData.session_uuid);
        setFirstIteration(fetchedData.result);
        setData(fetchedData.result);
        setChangesMade(false);
        setStandByList([]);
        if (fetchedData.errors?.length > 0) {
          setShowAlertModal(true);
          setAlertMessage(fetchedData.errors.join("\n"));
          setHistoryMessage(fetchedData.history.join("\n"));
        }
      })
      .catch(handleError)
      .finally(() => setIsResetting(false));
  };

  /**
   * Recalculate route with current modifications
   */
  const handleRecalculate = () => {
    if (!sessionUuid) {
      handleError({ message: "No active session found. Please fetch data first." });
      return;
    }

    setIsRecalculating(true);
    setHistory(prev => [...prev, { timestamp: new Date(), data }]);

    // Check for renamed points
    let hasNewName = false;
    data.stations.forEach(station => {
      station.itinerary.forEach(item => {
        if (item.new_name !== null) hasNewName = true;
      });
    });

    const recalculatePromise = hasNewName
      ? postEditPoints(sessionUuid, vertederoParameter, data, selectedDate)
      : postRecalculate(sessionUuid, vertederoParameter, data, selectedDate);

    recalculatePromise
      .then(newData => {
        if (hasNewName) {
          newData.result.stations.forEach(station => {
            station.itinerary.forEach(item => {
              if (item.new_name !== null) item.new_name = null;
            });
          });
        }
        setData(newData.result);
        setChangesMade(false);
      })
      .catch(handleError)
      .finally(() => setIsRecalculating(false));
  };

  /**
   * Handle route remodeling with standby list
   */
  const handleRemodel = () => {
    if (!sessionUuid) {
      handleError({ message: "No active session found. Please fetch data first." });
      return;
    }

    setIsRemodeling(true);
    setHistory(prev => [...prev, { timestamp: new Date(), data }]);

    postRemodel(sessionUuid, vertederoParameter, standByList, selectedDrivers, availableContainers)
      .then(newData => {
        setData(newData.result);
      })
      .catch(handleError)
      .finally(() => setIsRemodeling(false));
  };

  /**
   * Navigate to history view with current session state
   */
  const handleHistoryClick = () => {
    navigate("/history", {
      state: {
        history,
        currentSession: {
          data,
          firstIteration,
          sessionUuid,
          selectedDrivers,
          selectedDate,
          availableContainers,
          vertederoParameter
        }
      }
    });
  };

  // ----- Error and Modal Handlers -----

  const handleError = (error) => {
    setErrorMessage(error.message);
    setShowNetworkError(true);
  };

  const handleCloseAlertModal = () => {
    setShowAlertModal(false);
    setAlertMessage("");
  };

  const handleSubmit = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setIsSubmitted(false);
    setErrorMessage("");
    setShowNetworkError(false);
  };

  const handleConfirmSubmit = () => {
    setIsSubmitting(true);
    const finalData = data.stations.map((station, index) => ({
      ...station,
      driver_id: drivers[index].id,
    }));

    postFinalData(finalData)
      .then(() => {
        setIsSubmitting(false);
        setIsSubmitted(true);
      })
      .catch(() => {
        setIsSubmitting(false);
        setErrorMessage("Ocurrió un error al enviar los datos.");
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Debe recalcular antes de enviar
    </Tooltip>
  );

  // ----- Render Logic -----
  return (
    <Container fluid style={{ padding: 0, margin: 0, maxWidth: '100vw', overflow: 'hidden' }}>
      {/* Header Section */}
      <div className="header" style={{ boxShadow: '0 4px 4px 0 rgba(0,0,0,0.2)' }}>
        <Row className="align-items-center" style={{ margin: '5px' }}>
          <Col xs={12} md={4} className='img-logo'>
            <img src={img_logo} alt="Logo Azul" />
          </Col>
          <Col xs={12} md={8} className="d-flex justify-content-end">
            <HomeButtonBar
              handleHistoryClick={handleHistoryClick}
              handleReset={handleReset}
              handleRemodel={handleRemodel}
              isResetting={isResetting}
              isRecalculating={isRecalculating}
              handleRecalculate={handleRecalculate}
              handleOpenDrivers={() => setShowSelectDrivers(true)}
              standbyListUsed={standbyListUsed}
              renderTooltip={renderTooltip}
              handleSubmit={handleSubmit}
              changesMade={changesMade}
              vertederoParameter={vertederoParameter}
              setVertederoParameter={setVertederoParameter}
              isRemodeling={isRemodeling}
              setIsRemodeling={setIsRemodeling}
              availableContainers={availableContainers}
            />
          </Col>
        </Row>
        <TotalCostHome
          data={data}
          firstIteration={firstIteration}
          isFetching={isFetching}
          selectedDate={selectedDate}
          handleOpenDrivers={handleOpenDrivers}
        />
      </div>

      {/* Main Content */}
      {data === null && !isFetching ? (
        <div className="text-center mt-4">
          <Button
            onClick={handleOpenDrivers}
            title="Haz clic para iniciar la carga de datos"
            className='btn-start'
            size='lg'
          >
            <PlayFill className="me-2" size={24} />
            Iniciar Proceso de Carga
          </Button>
        </div>
      ) : data === null && isFetching ? (
        <div className="text-center mt-4">
          <Spinner />
        </div>
      ) : (
        <Container className="px-4 container-bg" fluid style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '0 10px' }}>
          <DraggableLists
            lists={data}
            setLists={setData}
            setStandByListUsed={setStandByListUsed}
            setChangesMade={setChangesMade}
            firstIteration={firstIteration}
            drivers={drivers}
            setDrivers={setDrivers}
            standByList={standByList}
            setStandByList={setStandByList}
          />
        </Container>
      )}

      {/* Modals and Alerts */}
      <ErrorMessage
        show={showNetworkError}
        onHide={handleCloseModal}
        errorMessage={errorMessage}
      />
      <AlertModal
        show={showAlertModal}
        onHide={handleCloseAlertModal}
        alertMessage={alertMessage}
        historyMessage={historyMessage}
      />
      <SelectDriversModal
        show={showSelectDrivers}
        setShowSelectDrivers={setShowSelectDrivers}
        drivers={drivers}
        setDrivers={setDrivers}
        handleError={handleError}
        handleFetchData={handleFetchData}
        setSelectedDrivers={setSelectedDrivers}
        selectedDrivers={selectedDrivers}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        availableContainers={availableContainers}
        setAvailableContainers={setAvailableContainers}
      />
      <SubmitModal
        showModal={showModal}
        errorMessage={errorMessage}
        handleCloseModal={handleCloseModal}
        handleConfirmSubmit={handleConfirmSubmit}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
      />
    </Container>
  );
};

export default Home;