// History.jsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, ListGroup, Button, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { fetchSessions, fetchSessionDetails, deleteSession, fetchSessionModifications } from '../../api/sessions';
import StaticRouteList from './StaticRouteList';

/**
 * History Component
 * Displays and manages the history of route sessions and their modifications.
 * Allows viewing, restoring, and managing previous route configurations.
 */
const History = () => {
  // Navigation and Routing
  const location = useLocation();
  const navigate = useNavigate();

  // Session Configuration State
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [availableContainers, setAvailableContainers] = useState(1);
  const [vertederoParameter, setVertederoParameter] = useState('cheapest');
  const [firstIteration, setFirstIteration] = useState(null);

  // Session Management State
  const [sessions, setSessions] = useState([]);
  const [expandedSession, setExpandedSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deletingSession, setDeletingSession] = useState(null);

  /**
   * Initialize session configuration from router state
   */
  useEffect(() => {
    const { currentSession } = location.state || {};
    if (currentSession) {
      setSelectedDrivers(currentSession.selectedDrivers);
      setSelectedDate(currentSession.selectedDate);
      setAvailableContainers(currentSession.availableContainers);
      setVertederoParameter(currentSession.vertederoParameter);
      setFirstIteration(currentSession.firstIteration);
    }
  }, [location.state]);

  /**
   * Load initial session data on component mount
   */
  useEffect(() => {
    loadSessions();
  }, []);

  /**
   * Fetch sessions from the last 7 days
   */
  const loadSessions = async () => {
    try {
      setLoading(true);
      const response = await fetchSessions(7);
      setSessions(response.sessions);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle session expansion and fetch detailed data
   */
  const handleCardClick = async (sessionUuid) => {
    if (expandedSession === sessionUuid) {
      setExpandedSession(null);
      return;
    }

    try {
      const [sessionDetails, modificationsResponse] = await Promise.all([
        fetchSessionDetails(sessionUuid),
        fetchSessionModifications(sessionUuid)
      ]);

      setSessions(sessions.map(session =>
        session.uuid === sessionUuid
          ? { ...sessionDetails, modifications: modificationsResponse.modifications }
          : session
      ));
      setExpandedSession(sessionUuid);
    } catch (error) {
      setError(error.message);
    }
  };

  /**
   * Delete a session and its modifications
   */
  const handleDeleteSession = async (sessionUuid) => {
    try {
      setDeletingSession(sessionUuid);
      await deleteSession(sessionUuid);
      setSessions(sessions.filter(session => session.uuid !== sessionUuid));
    } catch (error) {
      setError(error.message);
    } finally {
      setDeletingSession(null);
    }
  };

  /**
   * Restore a previous version of a route configuration
   */
  const handleRestoreVersion = (sessionUuid, modificationData) => {
    navigate('/home', {
      state: {
        data: modificationData,
        sessionUuid: sessionUuid,
        selectedDrivers,
        selectedDate,
        availableContainers,
        vertederoParameter,
        firstIteration,
        isRestoredVersion: true
      }
    });
  };

  /**
   * Navigate back to home while preserving session state
   */
  const handleBackButton = () => {
    const { currentSession } = location.state || {};
    navigate('/home', { state: currentSession });
  };

  // Loading State
  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <Spinner animation="border" />
      </Container>
    );
  }

  // Main Render
  return (
    <Container>
      <Row className="align-items-center pb-4 pt-4">
        <Col xs={12} md={6}>
          <h1>Historial de Rutas</h1>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-end">
          <Button onClick={handleBackButton}>Volver</Button>
        </Col>
      </Row>

      {/* Error Display */}
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      {/* Sessions Display */}
      {sessions.length === 0 ? (
        <Alert variant="info">No hay sesiones guardadas.</Alert>
      ) : (
        sessions.map((session) => (
          <SessionCard
            key={session.uuid}
            session={session}
            expandedSession={expandedSession}
            deletingSession={deletingSession}
            onCardClick={handleCardClick}
            onDelete={handleDeleteSession}
            onRestore={handleRestoreVersion}
          />
        ))
      )}
    </Container>
  );
};

/**
 * SessionCard Component
 * Displays individual session information and modifications
 */
const SessionCard = ({
  session,
  expandedSession,
  deletingSession,
  onCardClick,
  onDelete,
  onRestore
}) => {
  const formatDateTime = (dateString) => {
    // Create date object and adjust for local timezone
    const utcDate = new Date(dateString);
    const localOffset = utcDate.getTimezoneOffset();
    const localDate = new Date(utcDate.getTime() - (localOffset * 60000));

    // Format date as dd/mm/yyyy
    const formattedDate = localDate.toLocaleDateString('es', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '/');

    // Format time in local timezone
    const formattedTime = localDate.toLocaleTimeString('es', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'America/Santiago'  // Explicitly set Chilean timezone
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        {/* Session Header */}
        <Card.Title>
          <Row className="justify-content-between align-items-center">
            <Col>
              Sesión del {formatDateTime(session.created_at)}
            </Col>
            <Col className="text-end">
              <Button
                variant="info"
                className="me-2"
                onClick={() => onCardClick(session.uuid)}
              >
                {expandedSession === session.uuid ? 'Ocultar' : 'Ver Detalles'}
              </Button>
            </Col>
          </Row>
        </Card.Title>

        {/* Session Details */}
        <Card.Text>
          <strong>Vertedero:</strong> {session.vertedero_parameter} <br />
          <strong>Contenedores:</strong> {session.available_containers} <br />
          <strong>Conductores:</strong> {session.initial_drivers.length}
        </Card.Text>

        {/* Expanded View with Modifications */}
        {expandedSession === session.uuid && (
          <div className="mt-4">
            {/* Original Route */}
            <div className="border-bottom pb-3 mb-3">
              <h6>Ruta Original:</h6>
              <StaticRouteList routeData={session.initial_route_data} />
            </div>

            {/* Modifications List */}
            {session.modifications?.map((mod, idx) => (
              <div key={mod.uuid} className="border-bottom pb-3 mb-3">
                <Row className="align-items-center mb-3">
                  <Col>
                    <h6>Modificación {idx + 1}</h6>
                    <small>
                      Tipo: {mod.modification_type}<br />
                      Fecha: {new Date(mod.created_at).toLocaleString()}
                    </small>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => onRestore(session.uuid, mod.modified_route_data)}
                    >
                      Restaurar
                    </Button>
                  </Col>
                </Row>
                <StaticRouteList routeData={mod.modified_route_data} />
              </div>
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default History;